<template>
  <div class="page-container">
    <!--  列表区域  -->
    <div class="page-content">
      <el-table
        v-loading="companyListLoading"
        border
        row-key="id"
        :data="companyList"
        :tree-props="{ children: 'children' }"
        class="page-table"
        :empty-text="$t('lang_no_data')"
      >
        <el-table-column label="申请人账号" align="center" prop="param">
          <template v-slot="scope">
            {{ scope.row.param ? JSON.parse(scope.row.param).applyStaffLoginName : '' }}
          </template>
        </el-table-column>
        <el-table-column label="申请原因" align="center" prop="param">
          <template v-slot="scope">
            {{ scope.row.param ? JSON.parse(scope.row.param).applyReason : '' }}
          </template>
        </el-table-column>
        <el-table-column label="公章" align="center" prop="param">
          <template v-slot="scope">
            {{ scope.row.param ? JSON.parse(scope.row.param).data[0].sealName : '' }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="param" align="center" prop="param"></el-table-column> -->
        <el-table-column label="推送时间" align="center" prop="pushTime"></el-table-column>
        <el-table-column label="响应内容" align="center" prop="response">
          <template v-slot="scope">
            {{ scope.row.response ? JSON.parse(scope.row.response).message : '' }}
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" prop="status">
          <template v-slot="scope">
            <el-tag type="success" v-if="scope.row.status == 0">成功</el-tag>
            <el-tag type="error" v-if="scope.row.status != 0">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang_operation')" align="center">
          <template v-slot="scope">
            <el-button style="color: #007aff; margin-left: 10px" type="text" @click="open(scope.row, true)"> 详情 </el-button>
            <el-button style="color: #007aff; margin-left: 10px" type="text" @click="open(scope.row)">重新推送</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page-pager"
        background
        @size-change="changePageSize"
        @current-change="changePage"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog title="详情" :visible.sync="detailFlag" width="80%" :close-on-click-modal="false">
        <div class="section">
          <el-form :model="form" label-width="80px">
            <el-form-item label="申请原因">
              <el-input :disabled="disabled" v-model="form.applyReason" />
            </el-form-item>
            <el-form-item label="申请人账号">
              <el-input :disabled="disabled" v-model="form.applyStaffLoginName" />
            </el-form-item>
            <el-form-item label="业务类型">
              <el-input :disabled="disabled" v-model="form.businessType" />
            </el-form-item>
            <el-form-item label="用印份数">
              <el-input :disabled="disabled" v-model="form.fileNumber" />
            </el-form-item>
            <el-form-item label="响应数据">
              <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="form.response" :disabled="disabled"> </el-input>
            </el-form-item>
            <table width="100%" style="line-height: 3em">
              <thead style="background: rgb(68, 158, 255)">
                <tr>
                  <th>印章编号</th>
                  <th>印章名称</th>
                  <th>用印人登录名</th>
                  <th>使用次数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in form.data">
                  <th style="padding: 10px 20px">
                    <el-input :disabled="disabled" v-model="item.sealCode" />
                  </th>
                  <th style="padding: 10px 20px">
                    <el-input :disabled="disabled" v-model="item.sealName" />
                  </th>
                  <th style="padding: 10px 20px">
                    <el-input :disabled="disabled" v-model="item.staffLoginName" />
                  </th>
                  <th style="padding: 10px 20px">
                    <el-input :disabled="disabled" v-model="item.use_count" />
                  </th>
                </tr>
              </tbody>
            </table>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailFlag = false">取 消</el-button>
          <el-button @click="save" type="primary">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getLogList, getLogDetail, resetPush } from '@/api/data-manage/TaskApi';
export default {
  data() {
    return {
      companyListLoading: false,
      companyList: [],
      total: 100,
      query: {
        pageNum: 1,
        pageSize: 10
      },
      detailFlag: false,
      detail: '',
      form: {},
      disabled: false
    };
  },
  methods: {
    getList() {
      getLogList(this.query).then(res => {
        if (res.code == 1) {
          this.companyList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changePageSize(val) {
      this.query.pageSize = val;
      this.getList();
    },
    changePage(val) {
      this.query.pageNum = val;
      this.getList();
    },
    open(row, flag) {
      this.detailFlag = true;
      this.form = JSON.parse(row.param);
      this.form.response = row.response;
      this.disabled = flag;
    },
    save() {
      if (this.disabled == true) {
        this.detailFlag = false;
      } else {
        resetPush(this.form).then(res => {
          if (res.code == 1) {
            this.$message.success(res.message);
            this.detailFlag = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    }
  },
  mounted() {
    this.getList();
  }
};
</script>
<style>
th {
  border: 1px solid black;
}
</style>
