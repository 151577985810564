import HttpUtil from '@/utils/HttpUtil';

// 获取流程实例列表
export const getProcessInstances: any = (params?: any) => {
  let url = '/river-system/process/instance/query/list';
  return HttpUtil.post(url, params);
};

// 流程转交
export const delegateTask: any = (params?: any) => {
  let url = '/river-system/task/runtime/delegate';
  return HttpUtil.post(url, params);
};

// 获取流程跳转的下拉列表
export const queryJumpNodes: any = (params?: any) => {
  let url = '/river-system/workflow/model/query/jump/nodes';
  return HttpUtil.get(url, params);
};

// 跳转到指定节点
export const JumpToNode: any = (params?: any) => {
  let url = '/river-system/process/instance/to/node';
  return HttpUtil.post(url, params);
};

// 删除管理事项
export const deleteManage: any = (params?: any) => {
  let url = '/river-system/process/instance/delete';
  return HttpUtil.get(url, params);
};
// 
export const getLogList: any = (params?: any) => {
  let url = '/river-system/seal/task/log/list';
  return HttpUtil.get(url, params);
};
// 
export const getLogDetail: any = (params?: any) => {
  let url = '/river-system/seal/task/log/detail';
  return HttpUtil.get(url, params);
};
// 
export const resetPush: any = (params?: any) => {
  let url = '/river-system/seal/task/log/pushAgain';
  return HttpUtil.post(url, params);
};
